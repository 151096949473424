
export default defineNuxtRouteMiddleware(async (to, from) => {

  const { isSystemAdmin } = useAcl();

  if (isSystemAdmin()) {
    return;
  }

  return navigateTo("/");

});
